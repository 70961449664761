<template>
    <div>
        
        <div style="height:60px">

<div  style="padding-top:5px; padding-left:17px;padding-right: 17px">

     <div class="qmajorheader qalignmiddle qlargerfont">{{ langConv('Select Timezone') }}</div>
     <div class="qsubheader qalignmiddle qsmallfontplus">{{ langConv('Change to a different timezone using the list below') }}</div>


</div>
</div>

                <div style="padding:10px;">
                
                    
                
                </div>
                    <div class="grid-x grid-padding-x size-up-small-6" style="text-align:center">
                        <br />
                        <div class="cell small-12">
                            <dropdown ocname="timezones" @timezone_values="setTZValues" :ocnoitems="999" oclocaldata="file"  :ocdefaultvalue="langConv('Select timezone')" :ocnoaction="true" :ocshowlistonstart="true" :ocheightoflist="500"></dropdown>
                            
                        </div>

                        
                   

                        <div class="cell small-12" v-if="errors.length">
                            <b>Please correct the following error(s):</b>
                            <div v-for="(error,i) in errors" style="color:red" :key="i">{{ error }}</div>
                        </div>
                    <footer></footer>
                    </div>

                    
    </div>
</template>

<script>

    import dropdown from "../navigation/dropdown.vue"

    export default {
        
        data: function () {
            return {
                errors: []
            }
        },
        components: {
            dropdown
        },
        
        
        methods: {


            setTZValues: function (val, desc) {
               
                this.$store.commit("setTimediff", val)
                this.$store.commit("setTzdesc", desc)

            },    
            //function to set timezone screen 
            tzSetSave: function (val) {

                
                if (this.selectedtimezone != null) {

                    this.$store.commit("setTimediff", this.selectedtimezone)
                    this.$store.commit("setTzdesc", this.selectedtimezonename)

                    this.saveuser().then(resolve => {
                    //window.location.href = '/';
                });
  
                }
             
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>